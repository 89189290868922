const config = {
  env: "local",


  //apiUrl: "http://localhost:5520/admin/",
  //  apiUrl: "http://15.206.68.199:5628/admin",
  apiUrl:"https://api.eskocast.com/admin",

  shipRocketApiUrl: "https://apiv2.shiprocket.in/v1/external/",
};

export default config;
